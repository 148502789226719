require('./bootstrap');
const Swal = require('sweetalert2');

//De aquí en adelante definir las alertas
window.proccessAlert = function () {
    Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: 'Datos Procesados',
        showConfirmButton: false,
        timer: 1900
    })
}
window.successAlert = function () {
    Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: 'Datos Grabados Exitosamente',
        showConfirmButton: false,
        timer: 1900
    })
}
window.updatedAlert = function () {
    Swal.fire({
        position: 'bottom-end',
        icon: 'info',
        title: 'Datos Actualizados Exitosamente',
        showConfirmButton: false,
        timer: 1900
    })
}
window.deletedAlert = function () {
    Swal.fire({
        position: 'bottom-end',
        icon: 'success',
        title: 'Este regisgro se ha borrado.',
        showConfirmButton: false,
        timer: 1900
    })
}

window.errorAlert = function () {
    Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title: 'Datos no procesados.',
        showConfirmButton: false,
        timer: 1900
    })
}

window.deletedConfirAlert = function (parametro = 0) {
    Swal.fire({
        title: 'Esta seguro?',
        text: "Esto no se puede revertir!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!'
    }).then((result) => {
        if (result.isConfirmed) {
            Livewire.emit('remover', parametro);
        }
    })
}

window.errorMensajeAlert = function (parametro = 0) {
    Swal.fire({
        title: 'Identificación con errores!',
        position: 'bottom-end',
        icon: 'error',
        title: 'Mensaje ' + parametro,
        showConfirmButton: true,
        confirmButtonText: 'OK',
        timer: 2900
    })
}
window.infoMensajeAlert = function (parametro = 0) {
    Swal.fire({
        position: 'bottom-end',
        icon: 'info',
        title: parametro,
        showConfirmButton: false,
        timer: 1900
    })
}
window.saveConfirAlert = function () {
    Swal.fire({
        title: 'Esta seguro?',
        text: "Esto no se puede revertir!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Grabar!'
    }).then((result) => {
        if (result.isConfirmed) {
            Livewire.emit('grabar');

        }
    })
}
